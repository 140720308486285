<script lang="ts" setup>
import type { homeZigzag } from '~/interfaces/home.interface'

const target = ref([])

onMounted(() => {
  target.value.forEach((item) => {
    const rect = item.getBoundingClientRect()
    const visible = rect.top >= 0 && rect.bottom <= window.innerHeight
    if (visible) item.classList.add('active')
    else item.classList.remove('active')

    window.addEventListener('scroll', () => {
      const rect = item.getBoundingClientRect()
      const visible =
        rect.top >= 0 && rect.bottom <= (window.innerHeight / 6) * 5
      if (visible) item.classList.add('active')
    })
  })
  const focus = document.querySelectorAll('.focus')
  focus.forEach((focusItem) => {
    window.addEventListener('scroll', () => {
      const rect = focusItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) focusItem.classList.add('active')
    })
  })
})

const props = defineProps<{
  content?: homeZigzag
}>()

const splitTitles = ref({})

props.content.items.forEach((item) => {
  if (item.title) {
    const split = item.title.split(' ')
    splitTitles.value[item.id] = split
  }
})

const config = useRuntimeConfig()

const wealthTwicLink = ref('')

if (config.public.env === 'development' || config.public.env === 'staging') {
  wealthTwicLink.value = 'wealthLocal/'
} else if (config.public.env === 'preprod') {
  wealthTwicLink.value = 'wealthPreprod/'
} else {
  wealthTwicLink.value = ''
}

const zigzag = ref()
const isFirst = ref(false)
onMounted(() => {
  const firstZigzag = document.querySelector('.zigzag')
  if (firstZigzag === zigzag.value) isFirst.value = true
})
</script>

<template>
  <section v-if="content" ref="zigzag" class="zigzag !mb-0 first:mt-100">
    <UContainer>
      <div
        v-for="(zigzag, index) in content.items"
        :key="zigzag.id"
        class="mb-100"
        :id="zigzag.name ? zigzag.name : ''"
      >
        <AkRow no-gap :reverse-lg="zigzag.position === 'droite'">
          <AkCol :cols="12" :cols-lg="6">
            <div
              :class="{
                'focus duration-300': !isFirst || (isFirst && index >= 1),
              }"
              class="flex-col justify-center max-lg:mb-20 lg:flex lg:h-full"
            >
              <AkImage
                :no-twic="isFirst && index === 0"
                :align="zigzag.position === 'gauche' ? 'start' : 'end'"
                class="rounded-10"
                :image="zigzag.image"
                :legend-position="zigzag.legend_position"
              />
            </div>
          </AkCol>
          <AkCol :cols="12" :cols-lg="6">
            <div
              :class="[zigzag.position === 'droite' ? 'lg:mr-20' : 'lg:ml-20']"
              class="lg:flex lg:h-full lg:flex-col lg:items-start lg:justify-center"
            >
              <p v-if="zigzag.on_title" class="text-primary text-h5 mb-24">
                {{ zigzag.on_title }}
              </p>
              <component
                :is="zigzag.tag ? zigzag.tag : 'div'"
                v-if="zigzag.title"
                class="text-primary text-h3 font-semibold"
              >
                <span
                  v-for="title in splitTitles[zigzag.id]"
                  ref="target"
                  class="title active inline-block"
                  >{{ title }}&nbsp;
                </span>
              </component>
              <div class="my-24 h-2 w-62 bg-secondary" />
              <AkText
                v-if="zigzag.text"
                class="text-primary mb-24 text-p1"
                :text="zigzag.text"
              />
              <AkButton
                v-if="zigzag.button"
                class="max-lg:w-full max-lg:justify-center max-lg:text-center"
                :button="zigzag.button"
              />
            </div>
          </AkCol>
        </AkRow>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.title {
  color: #ccc;

  &.active {
    @apply text-primary;
  }
}
.focus {
  transform: scale(1.1);
  opacity: 0;
  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
